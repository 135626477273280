import React, { useState } from "react";

import LabLayout from "../../layouts/lab";
import { LineChart, BarChart, Table } from "../../components/dashboard";
import { CreateTeam } from "../../components/dashboard/Create";

/**
 * TODO: Y'know, delete this and use actual firebase data
 */
const DUMMY_INDEX_DATA: Array<SmallDashCardProps> = [
  {
    headline: "Tests Passing",
    data: "99%",
    delta: 10,
    color: "indigo",
    symbol: "las la-comment"
  },
  {
    headline: "New Comments",
    data: "5",
    delta: 5,
    color: "indigo",
    symbol: "las la-comment"
  },
  {
    headline: "New Workflows",
    data: "10",
    delta: 110,
    color: "orange",
    symbol: "las la-book"
  },
  {
    headline: "New Members",
    data: "10",
    delta: 110,
    color: "orange",
    symbol: "las la-book"
  }
];

export default () => {
  const [createTeamOpen, setCreateTeamOpen] = useState(false);
  return (
    <>
      <CreateTeam open={createTeamOpen} closeCallback={setCreateTeamOpen} />
      <LabLayout title="Lab">
        {/* Mini Card Info Region */}
        <div className="relative bg-teal-400 md:pt-32 pb-32 pt-12">
          <div className="px-4 md:px-10 mx-auto w-full">
            {/* Card stats */}
            <div className="flex flex-wrap">
              {DUMMY_INDEX_DATA.map((cardData) => (
                <SmallDashCard {...cardData} key={cardData.headline} />
              ))}
            </div>
            <h1 className="text-sm text-white uppercase font-bold my-5">
              Recent Teams
            </h1>
            <div className="flex flex-wrap">
              {DUMMY_INDEX_DATA.map((d) => (
                <SmallDashCard {...d} key={d.headline} />
              ))}
            </div>
          </div>
        </div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap">
            <LineChart />
            <BarChart />
          </div>
          <Table
            data={{
              "Project Name": ["Demo Workflows"],
              Collaborators: ["5"],
              Workflows: ["2"],
              "Productivity Rating Delta": ["46%"]
            }}
            title="Recent Projects"
          />
          <Table
            data={{
              "Workflow Name": ["Hydrology", "Smoke Detection"],
              "# of Notebooks": ["5", "10"],
              "% Passing Tests": ["60%", "70%"]
            }}
            title="Recent Workflows"
          />
          <footer className="block py-4">
            <div className="container mx-auto px-4">
              <hr className="mb-4 border-b-1 border-gray-300" />
              <div className="flex flex-wrap items-center md:justify-between justify-center">
                <div className="w-full md:w-6/12 px-4">
                  <div className="text-sm text-gray-600 font-semibold py-1">
                    Copyright © {new Date().getFullYear()} SDSC/HDSI WoRDS Lab
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </LabLayout>
    </>
  );
};

interface SmallDashCardProps {
  headline: string;
  data: string;
  delta: number;
  // Limit to TailwindCSS colors
  color:
    | "black"
    | "white"
    | "gray"
    | "red"
    | "yellow"
    | "green"
    | "blue"
    | "indigo"
    | "orange";

  // https://icons8.com/line-awesome, just use the css classname
  symbol: string;
}

/**
 * The small card used at the top of the dashboard page. Generally takes up
 * 1/4th of the horizontal container it's in.
 * @param props: Adheres to the SmallDashCardProps interface, defined by TS.
 */
const SmallDashCard = ({
  headline,
  data,
  delta,
  color,
  symbol
}: SmallDashCardProps) => (
  <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
    <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
      <div className="flex-auto p-4">
        <div className="flex flex-wrap">
          <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
            <h5 className="text-gray-500 uppercase font-bold text-xs">
              {headline}
            </h5>
            <span className="font-semibold text-xl text-gray-800">{data}</span>
          </div>
          <div className="relative w-auto pl-4 flex-initial">
            <div
              className={`text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-${color}-500`}
            >
              <i className={symbol} />
            </div>
          </div>
        </div>
        <p className="text-sm text-gray-500 mt-4">
          <span className="text-green-500 mr-2">
            <i className="las la-arrow-up" /> {delta}%
          </span>
          <span className="whitespace-no-wrap">Since last month</span>
        </p>
      </div>
    </div>
  </div>
);
